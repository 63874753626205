<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `Risale- Nur | ${route.name}` }}</template>
  </metainfo>
  <header />
  <router-view />
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { themeMode } from "@/core/helpers/config";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { ExActions } from "@/exstore/ExStoreEnums";
import { useRoute } from "vue-router";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const route = useRoute();
    useMeta({
      title: "",
      htmlAttrs: { lang: "tr", amp: true },
    });

    onMounted(() => {
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      /**
       *  Sets a mode from configuration
       */
      store.dispatch(Actions.SET_THEME_MODE_ACTION, themeMode.value);

      nextTick(() => {
        initializeComponents();

        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });

      store.dispatch(ExActions.UPDATE_USER);
    });

    watch(
      () => route.name,
      async (newName) => {
        // || newName === "lugat-kontrol"
        if (
          newName === "lugat-yonetimi" ||
          newName === "lugat-list" ||
          newName === "lugat-kontrol" ||
          newName === "paragraf-18-list"
        ) {
          store.commit(Mutations.SET_LAYOUT_CONFIG_PROPERTY, {
            property: "content.container",
            value: "fluid",
          });
        } else {
          store.commit(Mutations.SET_LAYOUT_CONFIG_PROPERTY, {
            property: "content.container",
            value: "fixed",
          });
        }
      }
    );
    return {
      route,
    };
  },
});
</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/element-ui.dark";
@import "assets/sass/plugins";
@import "assets/sass/style";

//RTL version styles
//@import "assets/css/style.rtl.css";

#app {
  display: contents;
}
</style>
