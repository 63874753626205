import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
// import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/user-list",
        name: "user-list",
        component: () => import("@/views/user/UserList.vue"),
      },
      {
        path: "/eser-paragraf-eslestir/:eser_id/:sayfa_num",
        name: "eser-paragraf-eslestir",
        component: () => import("@/views/eserparagraf/EserParafgrafEslestir.vue"),
      },
      {
        path: "/fihrist-list/:eser_id",
        name: "fihrist-list",
        component: () => import("@/views/fihrist/FihristList.vue"),
      },
      {
        path: "/meal-list/:eser_id",
        name: "meal-list",
        component: () => import("@/views/meal/MealList.vue"),
      },
      {
        path: "/paragraf-list/:eser_id/:sayfa_num",
        name: "paragraf-list",
        component: () => import("@/views/paragraf/Paragraf.vue"),
      },
      {
        path: "/osmanlica-latince-eslestirme-hatalari/:eser_id",
        name: "osmanlica-latince-eslestirme-hatalari",
        component: () => import("@/views/osmanlica-latince-eslestirme-hatalari/osmanlicaLatinceEslestirmeHatalari.vue"),
      },
      {
        path: "/paragraf-18-list/:eser_id/:sayfa_num",
        name: "paragraf-18-list",
        component: () => import("@/views/paragraf/Paragraf18Satir.vue"),
      },
      {
        path: "/problemli-paragraflar",
        name: "problemli-paragraflar",
        component: () => import("@/views/paragraf/ProblemliParagraflar.vue"),
      },
      {
        path: "/nusha-birligi/:kaynakeser_id/:hedefeser_id",
        name: "nusha-birligi",
        component: () => import("@/views/nushabirligi/NushaBirligi.vue"),
      },
      {
        path: "/hata-list",
        name: "hata-list",
        component: () => import("@/views/hata/HataList.vue"),
      },
      {
        path: "/osmSayfa/:eser_id/:sayfa_num",
        name: "osm-sayfa",
        component: () => import("@/views/sayfa/OsmSayfa.vue"),
      },
      {
        path: "/latSayfa/:eser_id/:sayfa_num",
        name: "lat-sayfa",
        component: () => import("@/views/sayfa/LatSayfa.vue"),
      },
      {
        path: "/hata-list",
        name: "hata-list",
        component: () => import("@/views/hata/HataList.vue"),
      },
      {
        path: "/hasiye-list/:eser_id",
        name: "hasiye-list",
        component: () => import("@/views/hasiye/HasiyeliSayfalar.vue"),
      },
      {
        path: "/lugat-yonetimi/:eser_id/:sayfa_num",
        name: "lugat-yonetimi",
        component: () => import("@/views/lugat/LugatYonetimi.vue"),
      },
      {
        path: "/lugat-list/:eser_id/:sayfa_num",
        name: "lugat-list",
        component: () => import("@/views/lugat/LugatList.vue"),
      },
      {
        path: "/arama",
        name: "arama-home",
        component: () => import("@/views/arama/AramaHome.vue"),
      },
      {
        path: "/arama/:metin",
        name: "arama",
        component: () => import("@/views/arama/AramaPage.vue"),
      },
      {
        path: "/bulunamayan",
        name: "Bulunamayan",
        component: () => import("@/views/bulunamayan/BulunamayanKelime.vue"),
      },
      {
        path: "/lugat-kontrol/:eser_id/:sayfa_num",
        name: "lugat-kontrol",
        component: () => import("@/views/lugat/LugatKontrol.vue"),
      },
      {
        path: "/eslesmeyenler-list/:eser_id/:sayfa_num",
        name: "eslesmeyenler-list",
        component: () => import("@/views/lugat/Eslesmeyenler.vue"),
      },
      {
        path: "/osmanlica-latince",
        name: "osmanlica-latince",
        component: () => import("@/views/osmanlica-latince-eslestirme-hatalari/OsmanlicaLatinceList.vue"),
      },
      {
        path: "/account",
        name: "account",
        component: () => import("@/views/user/Account.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/user/SignIn.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  switch (to.name) {
    case "404" || "500":
      next();
      return;
    case "sign-in":
      if (store.getters.isUserAuthenticated) {
        next({ name: "dashboard" });
        return;
      }
      break;
  }
  next();
  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
